import $ from "jquery";
import { defineAsyncComponent } from "vue";
const PaneSection = defineAsyncComponent(() => import("@/components/pane-section.vue"));
const SearchBox = defineAsyncComponent(() => import("@/components/search-box.vue"));

function adjustScroll() {
  let homeHeader = $(".site-header");
  let bodyElement = $("body");
  let pageHeader = $(".page-header.fixie");
  let scroll = $(window).scrollTop();

  if (scroll >= 150) {
    homeHeader.addClass("scroll");
    bodyElement.addClass("scroll");
  } else {
    homeHeader.removeClass("scroll");
    bodyElement.removeClass("scroll");
  }
  if (scroll >= 700) {
    pageHeader.addClass("active");
  } else {
    pageHeader.removeClass("active");
  }
}

let mainTimeout;

export default {
  beforeMount() {
    $(window).on("scroll.header", adjustScroll);
  },

  beforeUnmount() {
    $(window).off("scroll.header");
  },

  components: {
    PaneSection,
    SearchBox,
  },

  data() {
    return { activePane: null, currentStep: null, isSearchToggled: false, modals: {} };
  },

  computed: {},

  methods: {
    closeModal(key) {
      this.modals[key] = false;
    },

    onSectionClick(event) {
      if (this.activePane && event.target.classList.contains("pane-section")) {
        this.toggleMain();
      }
    },

    openModal(key) {
      this.modals[key] = true;
    },

    toggleMain() {
      this.activePane = null;

      $("#cottonbureau-app").css({
        height: "",
        maxHeight: "",
      });

      clearTimeout(mainTimeout);

      mainTimeout = setTimeout(() => {
        this.currentStep = null;
      }, 400);
    },

    toggleMenu() {
      this.activePane = "menu";
      this.currentStep = null;
    },

    toggleSearch(value) {
      this.isSearchToggled = value;
    },
  },

  mounted() {
    adjustScroll();
  },
};
