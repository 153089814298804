"use strict";

import $ from "jquery";

let POLYESTER = {};

POLYESTER.init = function () {
  $(document).on("focusin", ".Form-input", function () {
    $(this).addClass("Form-input--is-focused");
  });

  $(document).on("focusout", ".Form-input", function () {
    $(this).removeClass("Form-input--is-focused");
  });
};

export default POLYESTER;
