import { defineAsyncComponent } from "vue";

const AnnouncementNewsletter = defineAsyncComponent(() => import("@/components/announcement-newsletter.vue"));
const TheFooter = defineAsyncComponent(() => import("@/layout/the-footer.vue"));

export default {
  components: {
    AnnouncementNewsletter,
    TheFooter,
  },

  data() {
    return {};
  },
};
